var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-lg-5",attrs:{"fluid":""}},[_c('app-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.isModalVisible),expression:"isModalVisible"}],attrs:{"modal":_vm.modal},on:{"submit-modal":_vm.submitModal,"selection-changed":_vm.onUnitOrDateChange,"date-changed":_vm.onUnitOrDateChange}}),_c('app-header',{attrs:{"title":_vm.title,"breadcrumbs":_vm.breadcrumbs,"actionButton":_vm.actionButton,"showButton":true},on:{"clicked":_vm.openCreateLeaveModal}}),_c('v-card',{staticClass:"rounded-card",attrs:{"flat":"","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.leaves.items,"options":_vm.options,"server-items-length":_vm.leaves.count,"loading":_vm.loading,"no-data-text":_vm.$t('messages.emptyState', { entity: _vm.$tc('models.leave.entity') }),"no-results-text":_vm.$t('messages.emptyState', { entity: _vm.$tc('models.leave.entity') }),"flat":""},on:{"update:options":[function($event){_vm.options=$event},_vm.updateOptions]},scopedSlots:_vm._u([{key:"item.recurrence",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("models.leave.attributes.recurrences." + (item.recurrence)))))])]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return _vm._l((item.unit),function(unit){return _c('v-chip',{key:unit,staticClass:"mr-1 my-1"},[_vm._v(" "+_vm._s(_vm.$t(("models.leave.attributes.units." + unit)))+" ")])})}},{key:"item.requiresApproval",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"d-flex justify-center",attrs:{"disabled":""},model:{value:(item.requiresApproval),callback:function ($$v) {_vm.$set(item, "requiresApproval", $$v)},expression:"item.requiresApproval"}})]}},{key:"item.canOverlap",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"d-flex justify-center",attrs:{"disabled":""},model:{value:(item.canOverlap),callback:function ($$v) {_vm.$set(item, "canOverlap", $$v)},expression:"item.canOverlap"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.editLeave(item)}}},[_c('v-icon',[_vm._v("mdi-pen")])],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteLeave(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }