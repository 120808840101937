<template>
  <v-container fluid class="px-lg-5">
    <app-modal
      v-show="isModalVisible"
      :modal="modal"
      @submit-modal="submitModal"
      @selection-changed="onUnitOrDateChange"
      @date-changed="onUnitOrDateChange"
    />
    <app-header
      :title="title"
      :breadcrumbs="breadcrumbs"
      :actionButton="actionButton"
      @clicked="openCreateLeaveModal"
      :showButton="true"
    />
    <v-card class="rounded-card" flat outlined>
      <v-data-table
        :headers="headers"
        :items="leaves.items"
        :options.sync="options"
        @update:options="updateOptions"
        :server-items-length="leaves.count"
        :loading="loading"
        :no-data-text="$t('messages.emptyState', { entity: $tc('models.leave.entity') })"
        :no-results-text="$t('messages.emptyState', { entity: $tc('models.leave.entity') })"
        flat
      >
        <template v-slot:[`item.recurrence`]="{ item }">
          <span>{{ $t(`models.leave.attributes.recurrences.${item.recurrence}`) }}</span>
        </template>
        <template v-slot:[`item.unit`]="{ item }">
          <v-chip v-for="unit in item.unit" :key="unit" class="mr-1 my-1">
            {{ $t(`models.leave.attributes.units.${unit}`) }}
          </v-chip>
        </template>
        <template v-slot:[`item.requiresApproval`]="{ item }">
          <v-checkbox class="d-flex justify-center" v-model="item.requiresApproval" disabled />
        </template>
        <template v-slot:[`item.canOverlap`]="{ item }">
          <v-checkbox class="d-flex justify-center" v-model="item.canOverlap" disabled />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon small @click="editLeave(item)">
            <v-icon>mdi-pen</v-icon>
          </v-btn>
          <v-btn icon small @click="deleteLeave(item)">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {leaveUnits} from 'constants';
import moment from 'moment';

export default {
  name: 'Leaves',
  data() {
    return {
      isModalVisible: false,
      title: {
        text: this.$tc('models.leave.entity', 2),
        icon: 'mdi-account-group'
      },
      modal: {
        show: false,
        id: '',
        behaviour: '',
        resetForm: false,
        title: '',
        submitButton: {
          label: this.$t('actions.save')
        },
        fields: {
          name: {
            type: 'text',
            key: 'name',
            value: '',
            label: this.$t('models.leave.attributes.name'),
            rules: [{name: 'required'}],
            classes: 'col-12 py-0',
            serverErrors: []
          },
          totalBalance: {
            type: 'text',
            key: 'totalBalance',
            value: '',
            label: this.$t('models.leave.attributes.totalBalance'),
            rules: [{name: 'required'}],
            classes: 'col-6 py-0',
            hint: this.$t('messages.hints.jiraConventions'),
            serverErrors: []
          },
          carryOverBalance: {
            type: 'text',
            key: 'carryOverBalance',
            value: '',
            label: this.$t('models.leave.attributes.carryOverBalance'),
            classes: 'col-6 py-0',
            hint: this.$t('messages.hints.jiraConventions'),
            serverErrors: []
          },
          maxConsecutiveDays: {
            type: 'text',
            key: 'maxConsecutiveDays',
            value: '',
            label: this.$t('models.leave.attributes.maxConsecutiveDays'),
            classes: 'col-6 py-0',
            hint: this.$t('messages.hints.jiraConventions'),
            serverErrors: []
          },
          recurrence: {
            type: 'select',
            key: 'recurrence',
            options: this.$t('models.leave.attributes.recurrenceOptions'),
            value: '',
            trackable: true,
            itemText: 'value',
            itemValue: 'key',
            label: this.$t('models.leave.attributes.recurrence'),
            rules: [{name: 'required'}],
            classes: 'col-6 py-0',
            serverErrors: []
          },
          startDate: {
            type: 'datepicker',
            key: 'startDate',
            itemText: 'value',
            itemValue: 'key',
            trackable: true,
            value: '',
            label: this.$t('models.leave.attributes.startDate'),
            rules: [{name: 'required'}],
            classes: 'col-6 py-0',
            serverErrors: []
          },
          endDate: {
            type: 'datepicker',
            key: 'endDate',
            itemText: 'value',
            itemValue: 'key',
            value: '',
            disabled: true,
            label: this.$t('models.leave.attributes.endDate'),
            classes: 'col-6 py-0',
            serverErrors: []
          },
          unit: {
            type: 'select',
            key: 'unit',
            options: [],
            itemText: 'value',
            itemValue: 'key',
            value: '',
            multiple: true,
            trackable: true,
            label: this.$t('models.leave.attributes.unit'),
            chips: true,
            rules: [{name: 'required'}],
            classes: 'col-12 py-0',
            serverErrors: []
          },
          googleCalendarId: {
            type: 'text',
            key: 'googleCalendarId',
            value: '',
            label: this.$t('models.office.attributes.googleCalendarId'),
            classes: 'col-12 py-0',
            serverErrors: []
          },
          requiresApproval: {
            type: 'checkbox',
            key: 'requiresApproval',
            value: false,
            label: this.$t('models.leave.attributes.requiresApproval'),
            classes: 'col-6 py-0',
            serverErrors: []
          },
          canOverlap: {
            type: 'checkbox',
            key: 'canOverlap',
            value: false,
            label: this.$t('models.leave.attributes.canOverlap'),
            classes: 'col-6 py-0',
            serverErrors: []
          }
        }
      },
      breadcrumbs: [
        {
          text: this.$t('layout.mainMenu.home'),
          to: {name: 'Home'}
        },
        {
          text: this.$t('layout.mainMenu.company'),
          to: {name: 'Leaves'},
          exact: true
        },
        {
          text: this.$t('layout.mainMenu.leaves'),
          to: {name: 'Leaves'},
          exact: true
        }
      ],
      actionButton: {
        text: this.$t('actions.create', {entity: this.$tc('models.leave.entity', 1)}),
        icon: 'mdi-plus'
      },
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['name'],
        sortDesc: [false],
        mustSort: ['true'],
        multiSort: false
      },
      headers: [{
        text: '',
        value: 'id',
        align: ' d-none'
      },
      {
        text: this.$t('models.leave.attributes.name'),
        value: 'name'
      },
      {
        text: this.$t('models.leave.attributes.totalBalance'),
        value: 'totalBalance'
      },
      {
        text: this.$t('models.leave.attributes.carryOverBalance'),
        value: 'carryOverBalance'
      },
      {
        text: this.$t('models.leave.attributes.maxConsecutiveDays'),
        value: 'maxConsecutiveDays'
      },
      {
        text: this.$t('models.leave.attributes.recurrence'),
        value: 'recurrence'
      },
      {
        text: this.$t('models.leave.attributes.unit'),
        value: 'unit'
      },
      {
        text: this.$t('models.leave.attributes.requiresApproval'),
        value: 'requiresApproval'
      },
      {
        text: this.$t('models.leave.attributes.canOverlap'),
        value: 'canOverlap'
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        width: '120px'
      }]
    };
  },
  computed: mapGetters(['leaves']),
  methods: {
    ...mapActions([
      'getLeaves',
      'destroyLeave',
      'createLeave',
      'updateLeave'
    ]),
    updateOptions(options) {
      this.loading = true;
      this.getLeaves(options).then(() => this.loading = false);
    },
    submitModal(data) {
      let form;
      switch (this.modal.behaviour) {
      case 'createLeave':
        this.createLeave(data).then((response) => {
          if (this.successCode(response.status)) {
            this.modal.show = false;
            this.updateOptions(this.options);
          } else {
            this.displayInlineServerErrors(this.modal.fields, response.errors);
          }
        });
        break;
      case 'updateLeave':
        form = {
          leaveId: this.modal.id,
          form: data
        };
        this.updateLeave(form).then((response) => {
          if (this.successCode(response.status)) {
            this.modal.show = false;
            this.updateOptions(this.options);
          } else {
            this.displayInlineServerErrors(this.modal.fields, response.errors);
          }
        });
        break;
      }
    },
    editLeave(data) {
      this.clearInlineServerErrors(this.modal.fields);
      this.modal.resetForm = false;
      this.modal.behaviour = 'updateLeave';
      this.modal.title = this.$t('views.leaves.updateLeave');
      this._.forEach(this.modal.fields, (field) => {
        this.modal.fields[field.key].value = data[field.key];
      });
      this.modal.id = data.id;
      this.modal.show = true;
      this.$nextTick(() => {
        this.modal.resetForm = false;
      });
    },
    openCreateLeaveModal() {
      this.modal.resetForm = true;
      this.modal.behaviour = 'createLeave';
      this.modal.title = this.$t('views.leaves.newLeave');
      this._.forEach(this.modal.fields, (field) => {
        if (field.type === 'checkbox') {
          field.value = false;
        } else {
          this.modal.fields[field.key].value = '';
        }
      });
      this.modal.show = true;
      this.$nextTick(() => {
        this.modal.resetForm = false;
      });
    },
    deleteLeave(leave) {
      this.$confirm(
        this.$t('messages.confirmation.delete', {entity: leave.name}),
        {
          buttonTrueText: this.$t('actions.confirm'),
          buttonFalseText: this.$t('actions.cancel')
        }
      ).then((confirmed) => {
        if (confirmed) {
          this.destroyLeave(leave.id).then((status) => {
            if (this.successCode(status)) {
              this.updateOptions(this.options);
            }
          });
        }
      });
    },
    onUnitOrDateChange(data) {
      if (this.modal.fields.startDate.value === '' || this.modal.fields.recurrence.value === '') {
        this.modal.fields.endDate.value = '';
      } else {
        this.modal.fields.endDate.value = moment(this.modal.fields.startDate.value)
          .add(1, this.modal.fields.recurrence.value)
          .subtract(1, 'days')
          .format('YYYY-MM-DD');
      }
    }
  },
  created() {
    const $this = this;
    for (const key in leaveUnits) {
      if (Object.prototype.hasOwnProperty.call(leaveUnits, key)) {
        $this.modal.fields.unit.options.push({
          key: leaveUnits[key],
          value: $this.$t(`models.leave.attributes.units.${leaveUnits[key]}`)
        });
      }
    }
  }
};
</script>
